<template>
  <div class="language-selector d-flex align-items-center">
    <select v-model="$i18n.locale" class="">
      <option
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :value="lang"
      >
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  data () {
    return { langs: ['en', 'de'] }
  }
}
</script>

<style lang="scss" scoped>
.language-selector {
  select {
    font-size: 18px;
    letter-spacing: 2.25px;
    border: none;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.65);
  }
}
</style>
